<template>
    <PageLayout>
        <template>

            <v-row>
                <v-col cols="12" class="px-0 mx-0 deal_title">
                    {{ $t('deal_number_head', {'number': number}) }}
                </v-col>
            </v-row>
            <v-row >
                <v-col cols="12" class="px-0 mx-0 pt-5">

                    <v-card>
                        <v-card-text>
                            <v-row class="pt-2">
                                <v-col cols="12" sm="4"
                                       :class="($vuetify.breakpoint.xsOnly  ) ? '' : 'd-flex justify-start'"
                                       class="my-0 py-0" v-if="author && author.name">
                                    <v-list class="my-0 py-0 background_color_transparent">
                                        <v-list-item class="mx-0 px-0">
                                            <v-list-item-avatar :size="$vuetify.breakpoint.xsOnly ? 61 : 51" class="mr-4 border1_B3B3B3" >
                                                <img :src="author.photo" v-if="author.photo"/>
                                                <v-icon v-else class="color_B3B3B3" size="20">
                                                    ${{'settingsIcon'}}
                                                </v-icon>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title class=" pb-1" v-text="author.name"></v-list-item-title>
                                                <v-list-item-subtitle class="deal_text" v-text="author.phone"></v-list-item-subtitle>
                                            </v-list-item-content>

                                        </v-list-item>
                                    </v-list>
                                </v-col>
                                <v-col cols="12" sm="6"
                                       :class="($vuetify.breakpoint.xsOnly  ) ? 'pl-4' : 'mx-0 px-0 d-flex justify-center'"
                                       class=" my-0 py-0 deal_col_50" v-if="company && company.name">
                                    <v-list class="my-0 py-0 background_color_transparent">
                                        <v-list-item class="mx-0 px-0">

                                            <v-list-item-content>
                                                <v-list-item-title class="pb-1" v-text="company.name"></v-list-item-title>
                                                <v-list-item-subtitle class="deal_text" v-text="company.bin_iin"></v-list-item-subtitle>
                                            </v-list-item-content>

                                        </v-list-item>

                                    </v-list>
                                </v-col>
                                <v-col cols="12" sm="2"
                                       :class="($vuetify.breakpoint.xsOnly ) ? '' : 'd-flex justify-end align-right'"
                                       class="my-0 py-0 deal_col_50" v-if="created_at">
                                    <v-list class="my-0 py-0 background_color_transparent">
                                        <v-list-item class="mx-0 px-0">
                                            <v-list-item-content>
                                                <v-list-item-title class=" pb-1 text-right" v-text="$moment(created_at).format('DD.MM.YYYY') "></v-list-item-title>
                                                <v-list-item-subtitle class="deal_text text-right" v-text="$t('date_created')"></v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                            </v-row>
                            <v-row v-if="!$vuetify.breakpoint.xsOnly && dealOrderItems.length > 0">
                                <v-col cols="12" class="pt-5">
                                    <v-divider
                                        style="max-width: 100%; border-top: solid 1px #B3B3B3"
                                        size="2"
                                        class="mx-0 px-0 my-0 py-0"
                                    ></v-divider>
                                </v-col>
                            </v-row>
                            <v-row v-if="dealOrderItems.length > 0">
                                        <v-col cols="12" class="my-0 py-0">
                                            <v-data-table :headers="headers" :items="dealOrderItems" :loading="loadingDealOrders" :locale="lang"
                                                          :loading-text="$t('loading_please_wait')"
                                                          class="elevation-0 px-0 mx-0 padding_0_tr_1 heightTable tableDealOrder"
                                                          hide-default-footer

                                                          :hide-default-header="(!$vuetify.breakpoint.xsOnly)"
                                                          :item-class="rowClass"

                                            >
                                                <template v-slot:header="{ props: { headers } }" v-if="(!$vuetify.breakpoint.xsOnly)">
                                                    <thead>
                                                    <tr >
                                                        <th v-for="h in headers" :key="h.value" >
                                                            <span>{{h.text}}</span>
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                </template>
                                                <template v-slot:item.name="{ item, index }" >
                                                    <div class="">{{item.name}}</div>
                                                </template>
                                                <template v-slot:item.count="{ item, index }" >
                                                    <span class="">{{item.count}}</span>
                                                </template>
                                                <template v-slot:item.price="{ item, index }" >
                                                    <span class="">{{item.price}} {{item.currency}}</span>
                                                </template>

                                                <template v-slot:item.discount="{ item, index }">
                                                    <span class="">{{item.discount}} {{item.currency}}</span>
                                                </template>

                                                <template v-slot:item.extra_charge="{ item, index }">
                                                    <span class="">{{item.extra_charge}} {{item.currency}}</span>
                                                </template>

                                                <template v-slot:item.total="{ item }">

                                                    <div v-if="item.total === item.total_usd" >
                                                        {{item.total}} {{item.currency}}
                                                    </div>
                                                    <div v-if="item.total !== item.total_usd"  style="position: relative" >
                                                        {{item.total}} {{item.currency}}
                                                        <div style="position: absolute;right: 0;" >{{item.total_usd}} $</div>
                                                    </div>
                                                </template>
                                            </v-data-table>
                                        </v-col>
                                    </v-row>
                            <v-row v-if="dealOrderItems.length > 0">
                                <v-col cols="12" class="my-5 py-2">
                                    <v-row class="mx-0 px-0 ">
                                        <v-col cols="12" sm="12" class="d-flex  mx-0 px-0">
                                            <div class="mr-auto"></div>
                                            <div :style="!$vuetify.breakpoint.xsOnly ? 'width: 25%' : 'width: 100%'">
                                                <v-card
                                                    elevation="0"
                                                    class=" rounded-md mx-0 px-0"
                                                    style="border: solid 1px #b7b7b7"




                                                >
                                                    <v-card-text>
                                                <v-row  class=" mx-0 px-0">
                                                    <v-col cols="12" class=" mx-0 px-0">
                                                        <div class="d-flex deal_bold" style="width: 100%" >
                                                            <div class="mr-auto" >{{$t('total_amount_without_vat')}}</div>
                                                            <div>{{deal_order_no_nds_total}} $</div>
                                                        </div>
                                                        <div class="d-flex mt-2 deal_bold"  style="width: 100%">
                                                            <div class="mr-auto" >{{$t('total_amount_vat')}}</div>
                                                            <div > {{ deal_order_nds_total }} $</div>
                                                        </div>
                                                        <div class="d-flex mt-2 deal_bold"  style="width: 100%">
                                                            <div class="mr-auto">{{$t('to_be_paid')}}</div>
                                                            <div > {{deal_order_total}} $</div>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                                    </v-card-text>
                                                </v-card>

                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <v-row class="mt-4">
                    <v-col cols="12" class="py-0 my-3 deal_title">
                        {{$t('transaction_tracking')}}
                    </v-col>
                    </v-row>
                    <v-card class="mt-4">
                        <v-card-text>
                            <v-row v-if="trackings.length > 0">
                                <template  v-if="$vuetify.breakpoint.smAndDown">
                                    <v-col cols="12">
                                        <ListItem
                                            v-for="item in trackings"
                                            :item="item"
                                            :key="item.id"
                                            @showDetailsApplication="showDetailsApplication(item)"
                                        >
                                        </ListItem>
                                    </v-col>
                                </template>
                                <template v-else>
                                    <v-col cols="12" class="py-5">
                                        <div>
                                            <v-row>
                                                <v-col cols="12" >

                                                    <v-row v-if="trackings.length > 0">
                                                        <v-col cols="2" class="my-0 py-0 pl-4">
                                                            <div class="color_grey_header">
                                                                {{$t('date_created')}}
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="3" class="my-0 py-0 pl-6">
                                                            <div class="color_grey_header">
                                                                {{$t('deal.self')}}
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="2" class="my-0 py-0 pl-4">
                                                            <div class="color_grey_header">
                                                                {{$t('importer')}}
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="2" class="my-0 py-0">
                                                            <div class="color_grey_header">
                                                                {{$t('working_on_application')}}
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="2" class="my-0 py-0 pl-6">
                                                            <div class="color_grey_header">
                                                                {{$t('container')}}
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="1" class="my-0 py-0">

                                                        </v-col>
                                                        <v-col cols="12" class="my-0 py-0 pt-1">

                                                            <v-card
                                                                elevation="0"
                                                                :hover="true"
                                                                v-for="(item, i) in trackings"
                                                                :key="'tracking_'+i"
                                                                class="mb-7 rounded-md border_card"

                                                                color="#FFFFFF"

                                                                @click="showDetailsApplication(item)"

                                                            >

                                                                <v-card-text class="px-0 pt-3 pb-6">

                                                                    <v-row class="px-5 pb-2">

                                                                        <v-col cols="2">
                                                                            <div class="deal_bold"  >
                                                                                {{ item.date_operation ? $moment(item.date_operation).format('DD.MM.YYYY') : '' }}
                                                                                <v-icon small color="primary">mdi-chevron-down</v-icon>
                                                                            </div>
                                                                        </v-col>
                                                                        <v-col cols="3">
                                                                            <div class="deal_bold" >
                                                                                {{item.number_deal}}
                                                                            </div>

                                                                            <div class="color_grey" v-if="item.type_transport">
                                                                                {{item.type_transport}}
                                                                            </div>
                                                                            <div class="color_grey" v-if="item.loading_type && item.loading_type.name">{{item.loading_type.name}}</div>
                                                                        </v-col>
                                                                        <v-col cols="2">
                                                                            <div class="deal_bold" v-if="item.uved_name">
                                                                                {{item.uved_name}}
                                                                            </div>
                                                                            <div class="color_grey" v-if="item.uved_phones && item.uved_phones.phone">
                                                                                {{item.uved_phones.phone}}

                                                                            </div>
                                                                        </v-col>
                                                                        <v-col cols="2">
                                                                            <div v-if="item.responsible && item.responsible.length > 0">
                                                                                <div  v-for="(responsible, i) in item.responsible.slice(0, 3)" :key="'responsible_'+i" 	>
                                                                                    <v-icon small>icon-user</v-icon>
                                                                                    {{ (responsible) ? responsible.name : '' }}
                                                                                </div>
                                                                            </div>
                                                                        </v-col>
                                                                        <v-col cols="2" class="pl-8">
                                                                            <div>
                                                                                <v-icon class="pb-2">icon-shipment</v-icon>
                                                                                <span class="deal_bold mx-2">{{ item.container_number ? item.container_number : $t('no_data') }}</span>
                                                                            </div>

                                                                        </v-col>
                                                                        <v-col cols="1" style="text-align: right">
                                                                            <div class="deal_bold">
                                                                                {{item.documents.length}}
                                                                                <v-icon small color="primary" class="pl-1">icon-file-text</v-icon>
                                                                            </div>
                                                                            <div v-if="false" class="deal_bold">
                                                                                {{item.deal_documents.length }}
                                                                                <v-icon small color="primary" class="pl-1">icon-file-text-check</v-icon>
                                                                            </div>
                                                                            <div v-if="false" class="deal_bold">
                                                                                Не подана
                                                                                <v-icon small color="primary" class="pl-1">icon-doc</v-icon>
                                                                            </div>
                                                                        </v-col>


                                                                    </v-row>
                                                                    <v-row class="px-5 pb-2">
                                                                        <v-col cols="2">
                                                                            <div v-if="!item.arrival_date_at_destination">
                                                                                <span style="color: #2b95eb">{{ $t('in_operation_for') }}:</span>
                                                                                <span class="color_grey"> {{ $t('for_day', {"day": item.day}) }}</span>
                                                                            </div>
                                                                            <div v-else>
                                                                                <span style="color: #2b95eb">{{ $t('arrived') }}: </span>
                                                                                <span class="color_grey">{{ $moment(item.arrival_date_at_destination).format('DD.MM.YYYY') }} </span>
                                                                            </div>
                                                                        </v-col>
                                                                        <v-col cols="3">
                                                                            <div>
                                                                                <span class="color_grey">{{ $t('current_station') }}:</span>
                                                                                <span class="deal_bold">
                                                                    {{ item.station ? item.station : item.departure }}
                                                                </span>
                                                                            </div>
                                                                        </v-col>
                                                                        <v-col cols="7" v-if="item.sub_status_name">
                                                                            <div >
                                                                                <span class="color_grey">{{ $t('delivery_status') }}:</span>
                                                                                <span class="deal_bold">
                                                                    {{ item.sub_status_name ? item.sub_status_name : ''}}
                                                                </span>
                                                                            </div>
                                                                        </v-col>
                                                                    </v-row>

                                                                    <v-row  class="px-5 pb-2">
                                                                        <v-col cols="12" v-if="!($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly)">
                                                                            <v-row >
                                                                                <v-col cols="12">
                                                                                    <ul class="pod_status d-flex">
                                                                                        <template v-for="(status_item, i) in item.deal_statuses">
                                                                                            <li :key="'status'+status_item.id" class="li1">
                                                                                                <div class="node " :class="parseInt(item.deal_status_sort) >= parseInt(status_item.sort) ? (item.step_stop === false ? 'grey_point' : 'blue_point') : 'grey_point'"></div>
                                                                                                <p :class="(i+1) === item.deal_statuses.length ? 'li_last' : ''"><span :class="parseInt(item.deal_status_sort, 10)  >= parseInt(status_item.sort , 10) ? 'blue_color' : ''">{{ status_item.client_name }}</span>
                                                                                                </p></li>
                                                                                            <li :key="'status'+status_item.id + '-' + status_item.id" v-if="(i+1) !== item.deal_statuses.length" class="my_divider "
                                                                                                :class="item.deal_status_sort > status_item.sort ? (item.step_stop === false ? 'grey_line' : 'blue_line') : 'grey_line'">
                                                                                            </li>
                                                                                        </template>
                                                                                    </ul>
                                                                                </v-col>
                                                                            </v-row>

                                                                        </v-col>
                                                                    </v-row>

                                                                </v-card-text>
                                                            </v-card>

                                                        </v-col>
                                                    </v-row>

                                                </v-col>
                                            </v-row>
                                        </div>

                                    </v-col>
                                </template>
                            </v-row>
                        </v-card-text>
                    </v-card>






                </v-col>
            </v-row>

            <v-dialog
                v-model="dialogDetailTracking"
                :width="!$vuetify.breakpoint.xsOnly ? 700 : '100%'"
                overlay-color="#edebeb"

                transition="dialog-bottom-transition"

                :fullscreen="$vuetify.breakpoint.xsOnly"
                :hide-overlay="$vuetify.breakpoint.xsOnly"
                :scrollable="$vuetify.breakpoint.xsOnly"

            >
                <v-card class="px-5 py-5">
                    <v-toolbar  elevation="0" style="background-color: #FFFFFF">
                        <div class="ma-auto ml-1">
                            <span>{{ $t('tracking_data') }}</span>
                        </div>
                        <div>
                            <v-icon @click="close_info()">mdi-close</v-icon>
                        </div>
                    </v-toolbar>
                    <v-card-title class="distance_0">
                        <v-row>
                            <v-col cols="12" class="page-layout__main">
                                <div class="tabs-nav">
                                    <div class="tabs-nav__inner">
                                        <div
                                            v-for="tab in data_info"
                                            :key="tab.type"
                                            class="tabs-nav__item cursor_pointer"
                                            :class="{ active: tab_type === tab.type }"
                                        >
                                            <button  @click="show_info(tab.type)">
                                                <v-icon class="tabs-nav__item-icon" small>{{ tab.icon }}</v-icon>
                                                {{ tab.name }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" v-if="tab_type==='data'">
                                <v-row>
                                    <v-col cols="12" class="mb-1 pb-0"><b>{{ $t('application_number') }}:</b> {{ detail_tracking.number ? detail_tracking.number : $t('no_data') }}</v-col>
                                    <v-col cols="12" class="my-0 py-0"><b>{{ $t('container_number') }}:</b> {{ detail_tracking.container_number ? detail_tracking.container_number : $t('no_data') }}</v-col>
                                    <v-col cols="12" class="my-1 py-0"><b>{{ $t('departures') }}:</b> {{ detail_tracking.departure ? detail_tracking.departure : $t('no_data') }}</v-col>
                                    <v-col cols="12" class="my-0 py-0"><b>{{ $t('destination') }}:</b> {{ detail_tracking.destination ? detail_tracking.destination : $t('no_data') }}</v-col>
                                    <v-col cols="12" class="my-0 py-0"><b>{{ $t('delivery_status') }}:</b> {{detail_tracking.sub_status_name ? detail_tracking.sub_status_name : $t('no_data') }}</v-col>
                                    <v-col cols="12" class="my-1 py-0"><b>{{ $t('type_container') }}:</b> {{ detail_tracking.type_container ? detail_tracking.type_container : $t('no_data') }}</v-col>
                                    <v-col cols="12" class="my-0 py-0"><b>{{ $t('type_transportation') }}:</b> {{ detail_tracking.type_transport ? detail_tracking.type_transport : $t('no_data') }}</v-col>
                                    <v-col cols="12" class="my-1 py-0"><b>{{ $t('download_type') }}:</b>{{ (detail_tracking.loading_type && detail_tracking.loading_type.name) ? detail_tracking.loading_type.code + ' ' + detail_tracking.loading_type.name : $t('no_data') }}</v-col>
                                    <v-col cols="12" class="my-0 py-0"><b>{{ $t('application_date') }}:</b> {{ detail_tracking.created_at ? $moment(detail_tracking.created_at).format('YYYY-MM-DD HH:mm') : $t('no_data') }}</v-col>
                                    <v-col cols="12" class="my-1 py-0"><b>{{ $t('platform_number') }}:</b> {{ detail_tracking.platform_number ? detail_tracking.platform_number : $t('no_data') }}</v-col>
                                    <v-col cols="12" class="my-0 py-0"><b>{{ $t('platform_number_knr') }}:</b> {{ detail_tracking.platform_number_knr ? detail_tracking.platform_number_knr : $t('no_data') }}</v-col>
                                    <v-col cols="12" class="my-1 py-0"><b>{{ $t('date_operation') }}:</b> {{ detail_tracking.date_operation ? $moment(detail_tracking.date_operation).format('YYYY-MM-DD HH:mm') : $t('no_data') }}</v-col>
                                    <v-col cols="12" class="my-0 py-0"><b>{{ $t('date_of_arrival_at_the_border') }}:</b>{{ detail_tracking.date_of_arrival_at_border ? $moment(detail_tracking.date_of_arrival_at_border).format('YYYY-MM-DD HH:mm') : $t('no_data') }}</v-col>
                                    <v-col cols="12" class="my-1 py-0"><b>{{ $t('arrival_date_at_destination') }}:</b>{{ detail_tracking.arrival_date_at_destination ? $moment(detail_tracking.arrival_date_at_destination).format('YYYY-MM-DD HH:mm') : $t('no_data') }}</v-col>
                                    <v-col cols="12" class="my-0 py-0"><b>{{ $t('distance_to_destination') }}:</b> {{detail_tracking.distance_to_destination ? detail_tracking.distance_to_destination : $t('no_data') }}</v-col>
                                    <v-col cols="12" class="my-1 py-0"><b>{{ $t('delivered') }}:</b> {{ detail_tracking.paid == true ? $t('yes') : $t('no') }}</v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" v-if="tab_type==='tracking'">

                                <v-row>
                                    <v-col cols="12" md="12" class="mb-1 pb-0">
                                        <div class="roadmap_wrapper">

                                            <ul class="roadmap">


                                                <li class="li1_2" v-if="tracking_geo.from">
                                                    <div class="node_2 blue_point_2"></div>
                                                    <p>
                                                        <span class="li_last_bold">{{ tracking_geo.from.name }}</span>
                                                        <br/>
                                                        <span class="grey_date_2">{{ tracking_geo.from.date_operation }}</span>
                                                    </p>
                                                </li>


                                                <template v-for="(item, t) in tracking_geo.tracking" >
                                                    <li v-if="tracking_geo.to.name !== item.name" class="my_divider_2 blue_line_2" :key="'geo2_'+t"></li>
                                                    <li v-if="tracking_geo.to.name !== item.name" class="li1_2" :key="'geo21_'+t">
                                                        <div class="node_2 blue_point_2"></div>
                                                        <p class="li_last_center_2">
                                                            <span class="li_last_bold">{{item.name}}</span>
                                                            <br/>
                                                            <span class="grey_date_2">{{ item.date_operation }}</span>
                                                        </p>
                                                    </li>
                                                </template>

                                                <li v-if="tracking_geo.to" :class="(tracking_geo.tracking && tracking_geo.tracking.length > 0 ? 'my_divider_2' : 'my_divider_3') + ' ' + (!detail_tracking.arrival_date_at_destination ? 'grey_line_2' : 'blue_line_2')"></li>
                                                <li v-if="tracking_geo.to" class="li1_2">
                                                    <div class="node_2" :class="!detail_tracking.arrival_date_at_destination ? 'grey_point_2' : 'blue_point_2'"></div>
                                                    <p class="li_last_finish_2">
                                                        <span class="li_last_bold">{{ tracking_geo.to.name }}</span>
                                                        <br/>
                                                        <span class="grey_date_2">{{ tracking_geo.to.date_operation }}</span>
                                                    </p>
                                                </li>

                                            </ul>


                                            <ul v-if="false" class="roadmap">
                                                <!-- Станция 1 -->
                                                <li class="li1_2">
                                                    <div class="node_2 blue_point_2"></div>
                                                    <p>
                                                        <span class="li_last_bold">Almaty 1</span>
                                                        <br/>
                                                        <span class="grey_date_2">2024-10-10</span>
                                                    </p>
                                                </li>

                                                <!-- Линия между станциями -->
                                                <li class="my_divider_3 grey_line_2"></li>
                                                <!-- Станция 4 -->
                                                <li class="li1_2">
                                                    <div class="node_2 grey_point_2"></div>
                                                    <p class="li_last_finish_2">
                                                        <span class="li_last_bold">Altyn Kol</span>
                                                        <br/>
                                                        <span class="grey_date_2">2024-10-10</span>
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>


                                    </v-col>
                                    <v-col cols="12" md="12" style="min-height: 370px" class="rounded-lg">
                                        <div >
                                            <yandex-map class="py-0 my-0 py-0" v-if="coords && showMap" ref="map"
                                                        :coords="coords"
                                                        :controls="[]"
                                                        :show-all-markers="showAllMarkers"
                                                        :zoom="zoom"
                                                        @map-was-initialized="initHandler"
                                                        style="background-color: #ffffff">
                                            </yandex-map>
                                        </div>
                                    </v-col>
                                </v-row>

                            </v-col>
                            <v-col cols="12" v-if="tab_type==='event'" style="min-height: 390px">
                                <v-row>
                                    <v-col cols="12" class="mb-1 pb-0">

                                        <template v-for="(item, t) in trackingLogItems">
                                            <v-row class="py-2">
                                                <v-col cols="9" sm="9" color="primary" class="my-0 py-0" >
                                                    <v-icon small color="primary" class="mr-2" v-if="!$vuetify.breakpoint.xsOnly">icon-check-circle-1</v-icon>
                                                    {{item.message}}
                                                </v-col>
                                                <v-col cols="3" sm="3" class="grey_date_2 text-right my-0 py-0">
                                                    {{item.created_at}}
                                                </v-col>
                                            </v-row>
                                        </template>

                                    </v-col>

                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>

                </v-card>
            </v-dialog>

            <v-dialog v-if="dialogDetails" v-model="dialogDetails" fullscreen hide-overlay transition="dialog-bottom-transition">
                <v-card>
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>{{ $t('details') }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn icon dark @click="cancelDetails()">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>

                    <v-card-text class="pa-0">
                        <v-row>
                            <v-col cols="12" sm="6" >
                                <v-col cols="12" class="py-1 text-right" v-if="(detailsItems.client !== null && typeof detailsItems.client.name !== null)"><b> Клиент:</b> {{ detailsItems.client.name ? detailsItems.client.name :  $t('no_data')  }}</v-col>
                                <v-col cols="12" class="py-1 text-right"><b> {{$t('application_number')}}:</b> {{ detailsItems.deal.number ? detailsItems.deal.number :  $t('no_data')  }}</v-col>
                                <v-col cols="12" class="py-1 text-right"><b> {{$t('container_number')}}:</b> {{ detailsItems.container_number ? detailsItems.container_number :  $t('no_data')  }}</v-col>

                                <v-col cols="12" class="py-1 text-right"><b> {{$t('departures')}}:</b> {{ detailsItems.departure.name ? detailsItems.departure.name :  $t('no_data')  }}</v-col>
                                <v-col cols="12" class="py-1 text-right"><b> {{$t('destination')}}:</b> {{ detailsItems.destination.name ? detailsItems.destination.name :  $t('no_data')  }}</v-col>
                                <v-col cols="12" class="py-1 text-right"><b> {{$t('type_container')}}:</b> {{ detailsItems.type_container.name ? detailsItems.type_container.name :  $t('no_data')  }}</v-col>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-col cols="12" class="py-1"><b> {{$t('status')}}:</b> {{ detailsItems.status.name ? detailsItems.status.name :  $t('no_data')  }}</v-col>
                                <v-col cols="12" class="py-1"><b> {{$t('application_date')}}:</b> {{ detailsItems.created_at ? $moment(detailsItems.created_at).format('YYYY-MM-DD HH:MM') : $t('no_data')  }}</v-col>
                                <v-col cols="12" class="py-1"><b> {{$t('date_operation')}}:</b> {{ detailsItems.date_operation  ? $moment(detailsItems.date_operation).format('YYYY-MM-DD HH:MM') : $t('no_data')  }}</v-col>
                                <v-col cols="12" class="py-1"><b> {{$t('date_of_arrival_at_the_border')}}:</b> {{ detailsItems.date_of_arrival_at_border ? $moment(detailsItems.date_of_arrival_at_border).format('YYYY-MM-DD HH:MM') :  $t('no_data')  }}</v-col>
                                <v-col cols="12" class="py-1"><b> {{$t('arrival_date_at_destination')}}:</b> {{ detailsItems.arrival_date_at_destination ? $moment(detailsItems.arrival_date_at_destination).format('YYYY-MM-DD HH:MM') :  $t('no_data')  }}</v-col>
                                <v-col cols="12" class="py-1"><b> {{$t('distance_to_destination')}}:</b> {{ detailsItems.distance_to_destination ? detailsItems.distance_to_destination :  $t('no_data')  }}</v-col>
                            </v-col>

                            <v-col class="py-0" cols="12" sm="2">

                            </v-col>
                            <v-col class="py-0" cols="12" sm="8">
                                <template>
                                    <v-timeline
                                        align-top
                                        :dense="$vuetify.breakpoint.smAndDown"
                                    >
                                        <v-timeline-item
                                            v-for="(item, i) in detailsItems.trackings"
                                            :key="i"
                                            color="primary"
                                            icon="mdi-chevron-down-circle-outline"
                                            fill-dot
                                            flat
                                        >
                                            <v-card
                                                color="primary"
                                                dark
                                            >
                                                <v-card-title class="title">
                                                    {{$t('updated_at')}}  : {{  item.date_operation ? $moment(item.date_operation).format('YYYY-MM-DD HH:MM') : $t('no_data')}}
                                                </v-card-title>
                                                <v-card-text>
                                                    <p style="line-height:25px">
                                                        <b>{{$t('distance_to_destination')}}:</b>  {{ item.distance_to_destination ? item.distance_to_destination :  $t('no_data')  }} <br/>
                                                        <b>{{$t('status')}}:</b> {{ item.status ? item.status :  $t('no_data')  }} <br/>
                                                        <b>{{$t('operations')}}:</b>  {{ item.station ? item.station :  $t('no_data')  }} <br/>
                                                        {{$t('date_operation')}}: {{  item.date_operation ? $moment(item.date_operation).format('YYYY-MM-DD HH:MM') : $t('no_data')}}
                                                    </p>

                                                </v-card-text>
                                            </v-card>
                                        </v-timeline-item>
                                    </v-timeline>
                                </template>
                            </v-col>
                            <v-col cols="12" sm="2">

                            </v-col>

                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <OpenFile :dialogOpenFile.sync="dialogOpenFile" :file="fileOpen"/>

        </template>
        <template v-slot:side>
            <v-row>
                <v-col cols="12" class="py-0 my-3 deal_title">
                    {{$t('chat_deals')}}
                </v-col>
                <v-col class=" my-0 py-0">
                    <TopicListItem
                        v-for="item in topicItems"
                        :item="item"
                        :key="item.id"
                    >
                    </TopicListItem>
                </v-col>
            </v-row>

            <v-row v-if="(documents.length > 0)">
                <v-col cols="12" class="py-0 my-3 deal_title">
                    {{$t('attached_documents')}}
                </v-col>
                <v-col cols="12" class="my-0 py-0">
                    <v-card>
                        <v-card-text>
                            <v-list class="background_color_transparent">
                                <template v-for="(item, index) in documents">
                                    <v-list-item
                                        :key="item.uuid"
                                        class="mx-0 px-0 my-0 py-0"
                                    >
                                        <v-list-item-avatar left height="40" width="20" class="background_color_transparent">
                                            <v-icon color="primary" left size="25" class="ml-0 pl-0">
                                                ${{'StoragesIcon'}}
                                            </v-icon>
                                        </v-list-item-avatar>


                                        <v-list-item-content class="py-0 my-0">
                                            <v-list-item-title>
                                                <ContextMenuFile :file="item" @add-document-form ="addDocumentForm"  @open-file="openFileContext"></ContextMenuFile>
                                            </v-list-item-title>
                                            <v-list-item-subtitle v-text="(item.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                                        </v-list-item-content>

                                    </v-list-item>

                                </template>
                                <template v-for="(item, index) in invoices">
                                    <v-list-item
                                        :key="item.uuid"
                                        class="cursor-pointer mx-0 px-0 my-0 py-0"
                                        @click="downloadFile(item.request_scanned)"
                                    >
                                        <v-list-item-avatar left height="40" width="20">
                                            <v-icon color="primary" left size="25" class="ml-0 pl-0">
                                                ${{'StoragesIcon'}}
                                            </v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content class="py-0 my-0">
                                            <v-list-item-title class="py-0 my-0 " v-html="item.request_scanned.file_name "></v-list-item-title>
                                            <v-list-item-subtitle class="py-0 my-0 mt-1 deal_text" v-html="(item.request_scanned.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                                        </v-list-item-content>

                                    </v-list-item>

                                </template>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>
    </PageLayout>


</template>
<script>

    import {mapGetters} from "vuex"
    import {loadYmap, yandexMap} from 'vue-yandex-maps'
    import PageLayout from "@/components/Leentech/PageLayout.vue"
    import ListItem from "@/components/Tracking/ListItem.vue";
    import TopicListItem from "@/components/Topic/ListItem.vue";
    import ContextMenuFile from "@/components/ContextMenuFile.vue";
    import OpenFile from '@/components/OpenFile.vue';

    export default {
        name: "DealForm",
        components: {
            PageLayout,
            yandexMap,
            ListItem,
            TopicListItem,
            ContextMenuFile,
            OpenFile
        },
        data() {
            return {
                progress:0,
                dialogForm: false,
                document_flow_urls_form: [],
                dialogOpenFile: false,
                fileOpen: {},
                invalid: false,
                deal: null,
                loading: false,
                deal_uuid: null,
                number: null,
                author: {},
                deal_status: null,
                company: null,
                documents: [],
                created_at: null,
                headers: [
                    {
                        text: this.$t('deal_type'),
                        align: "left",
                        sortable: false,
                        value: "name"
                    },
                    {
                        text: this.$t('quantity_short'),
                        align: "center",
                        sortable: false,
                        value: "count",
                        width:100
                    },
                    {
                        text: this.$t('price'),
                        align: "center",
                        sortable: false,
                        value: "price"
                    },
                    {
                        text: this.$t('discount'),
                        align: "center",
                        sortable: false,
                        value: "discount"
                    },
                    // {
                    //     text: this.$t('extra_charge'),
                    //     align: "center",
                    //     sortable: false,
                    //     value: "extra_charge"
                    // },
                    {
                        text: this.$t('cost'),
                        align: "right",
                        sortable: false,
                        value: "total",
                        width: 150,
                    }
                ],
                loadingDealOrders:false,
                dealOrderItems: [],
                deal_order_total: 0,
                deal_order_nds_total: 0,
                deal_order_no_nds_total: 0,
                trackings: [],
                invoices: [],
                selected: [],
                topicItems: [],
                clickMenuIcon: 0,
                info_type: "data",
                tracking_geo: [],
                referencePoints: [],
                showAllMarkers: true,
                mapMarker: null,
                zoom: 12,
                ymaps: null,
                coordinates: [],
                showMap: false,
                coordinatesPolygon: [],
                currency: null,
                currencyItems: [],
                loadingCurrency: false,
                status: null,
                statusItems: [],
                loadingStatus: false,
                sub_status: null,
                sub_statusItems: [],
                loadingSubStatus: false,
                dialogDetailTracking: false,
                detail_tracking: {},
                business_process: null,
                deal_status_sort: 1,
                responsibles: [],
                order_number: null,
                hs_code: null,

                tab_type: "data",
                data_info: [
                    {
                        "type": "data",
                        "name" : this.$t('data'),
                        "class": "mr-2",
                        "color": "primary",
                        "icon": 'icon-doc',
                    },
                    {
                        "type": "tracking",
                        "name": this.$t('tracking'),
                        "class": "border_0",
                        "icon": 'icon-binoculars',
                        "color": "color_B3B3B3"
                    },
                    {
                        "type": "event",
                        "name": this.$t('event'),
                        "class": "border_0",
                        "icon": 'icon-check-circle-1',
                        "color": "color_B3B3B3"
                    }
                ],
                dialogDetails: false,
                trackingLogItems: [],
            };
        },
        computed: {
            ...mapGetters(['lang', 'listLanguages']),
            coords: {
                get: function () {
                    return (this.coordinates && this.coordinates.length === 2) ? this.coordinates : [51, 71]
                },
                set: function (val) {
                    this.coordinates = (val && val.length === 2) ? val : [51, 71]
                }
            },
            mapSettings() {
                this.$yandex.lang = 'ru_RU'
                return this.$yandex
            },
            languages() {
                return this.listLanguages
            },
        },
        async mounted() {
            this.language = this.languages[this.tab]
            await this.getDeal()
            await this.getStatus()
            await this.getSubStatus()
            await this.getTracking()
            await this.getInvoices()
            await this.getCurrencies()
            await this.getDealOrders()
            await this.getTopic()
        },

        methods: {

            async ymapInit() {
                var _this = this
                if (window.ymaps) {
                    this.showMap = false
                    if (this.$refs.map && this.$refs.map.myMap) {
                        this.$refs.map.myMap.destroy()
                    }
                    document.getElementById("vue-yandex-maps").remove()
                    delete window.ymaps
                }
                await loadYmap({...this.mapSettings, debug: true})
                if (window.ymaps) {
                    window.ymaps.ready(function () {
                        _this.showMap = true
                    })
                }
            },
            initHandler(e) {
                var _this = this;
                if (this.coords && this.coords.length !== 2 && window.ymaps) {
                    var location = window.ymaps.geolocation.get({
                        provider: 'yandex',
                        mapStateAutoApply: true,
                        autoReverseGeocode: true
                    })
                    location.then(
                        function (result) {
                            //console.log(result.geoObjects.get(0).geometry.getCoordinates())
                            e.geoObjects.add(result.geoObjects)
                        },
                        function (err) {
                            console.log('Error: ' + err)
                        }
                    )
                }
                e.geoObjects.options.set('draggable', true)
                _this.myMap = e;
                var multiRoute = new window.ymaps.multiRouter.MultiRoute({
                    // Точки маршрута. Точки могут быть заданы как координатами, так и адресом.
                    referencePoints: this.referencePoints,
                    // referencePoints: [
                    //     [39.960675, 116.341702],
                    //     [55.584227, 37.385534], // улица Льва Толстого.
                    //     [43.222955, 76.955441],
                    //     [44.197558, 80.449582],
                    //
                    //
                    // ]
                }, {
                    // Внешний вид путевых точек.
                    wayPointStartIconColor: "#FFFFFF",
                    wayPointStartIconFillColor: "#B3B3B3",
                    // Внешний вид линии активного маршрута.
                    routeActiveStrokeWidth: 4,
                    routeActiveStrokeStyle: 'solid',
                    routeActiveStrokeColor: "#007EC9",
                    // Внешний вид линий альтернативных маршрутов.
                    routeStrokeStyle: 'dot',
                    routeStrokeWidth: 3,
                    // Автоматически устанавливать границы карты так,
                    // чтобы маршрут был виден целиком.
                    boundsAutoApply: true
                });


                //  console.log(multiRoute);

                _this.myMap.geoObjects.add(multiRoute);
                //e.geoObjects.add(multiRoute);

                var fullscreenControl = new window.ymaps.control.FullscreenControl();
                _this.myMap.controls.add(fullscreenControl);
                //  e.controls.add('FullscreenControl');
            },

            rowClass(){
                //  const rowClass = (this.$vuetify.breakpoint.xsOnly || this.$vuetify.breakpoint.smOnly) ? '' : 'rowClass'
              //  return 'rowDealOrder rowDealForm';
                return (!this.$vuetify.breakpoint.xsOnly) ? 'rowDealOrder rowDealForm' : 'rowMobiClass';
            },

            async getDeal() {
                var _this = this;
                this.progress = 0
                this.loading = true
                let params = {}
                if (this.language) {
                    params.language = this.language
                }
                await this.$http
                    .get(`client/deal/${this.$route.params.uuid}`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.deal_uuid = res.body.data.uuid
                        this.business_process = res.body.data.business_process
                        this.number = res.body.data.number
                        this.author = res.body.data.author

                        this.deal_status = res.body.data.deal_status.id
                        this.deal_status_sort = res.body.data.deal_status.sort

                        this.auditor = res.body.data.auditors
                        this.company = res.body.data.company
                        this.responsibles = res.body.data.responsibles
                        this.order_number = res.body.data.order_number
                        this.hs_code = res.body.data.hs_code

                        this.documents = res.body.data.documents
                        this.created_at = res.body.data.created_at
                      //  this.heading = this.number + '. ' + res.body.data.company.name

                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_deal'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async getCurrencies() {
                var _this = this;
                this.loadingCurrency = true;

                let params = {};
                if (this.language) {
                    params.language = this.language
                }



                await this.$http
                    .get(`client/currency`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {

                        this.currencyItems = res.body.data

                    })
                    .catch(err => {
                        this.currencyItems = []
                        this.$toastr.error(this.$t('failed_to_get_list_currencies'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loadingCurrency = false
                    })
            },
            async getStatus() {
                var _this = this;
                this.loadingSataus = true;

                let params = {};
                if (this.language) {
                    params.language = this.language
                }

                if (this.business_process) {
                    params.business_process = this.business_process
                }



                await this.$http
                    .get(`client/status`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {

                        this.statusItems = res.body.data

                    })
                    .catch(err => {
                        this.statusItems = []
                        this.$toastr.error(this.$t('failed_to_get_list_statuses'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loadingSataus = false
                    })
            },
            async getSubStatus() {
                var _this = this;
                this.loadingSubSataus = true;

                let params = {};
                if (this.language) {
                    params.language = this.language
                }



                await this.$http
                    .get(`client/sub_status`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {

                        this.sub_statusItems = res.body.data

                    })
                    .catch(err => {
                        this.sub_statusItems = []
                        this.$toastr.error(this.$t('failed_to_get_list_statuses'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loadingSubSataus = false
                    })
            },
            async getDealOrders() {

                this.loadingDealOrders = true
                let params = {}
                params.filter = 'search'
                params.deal_uuid = this.deal_uuid

                await this.$http
                    .get("client/deal_order", {
                        params: params,
                    })
                    .then(res => {
                        this.dealOrderItems = res.body.data
                        this.recalculate();
                    })
                    .catch(err => {
                        this.dealOrderItems = []
                    })
                    .finally(end => {
                        this.loadingDealOrders = false
                    })

            },
            async getTracking() {
                var _this = this;
                this.progress = 0
                this.loading = true
                let params = {}
                if (this.language) {
                    params.language = this.language
                }
                await this.$http
                    .get(`client/deal/${this.$route.params.uuid}/tracking`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.trackings = res.body.data

                    })
                    .catch(err => {
                        this.trackings = [];
                        this.$toastr.error(this.$t('failed_to_get_list_trackings'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async getInvoices() {
                var _this = this;
                this.loading = true;

                let params = {};
                if (this.language) {
                    params.language = this.language
                }



                await this.$http
                    .get(`client/deal/${this.$route.params.uuid}/invoices`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {

                        this.invoices = res.body.data

                    })
                    .catch(err => {
                        this.invoices = []
                        this.$toastr.error(this.$t('failed_to_get_list_invoices'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async getTopic() {
                var _this = this;
                this.loading = true;

                let params = {};
                if (this.language) {
                    params.language = this.language
                }



                await this.$http
                    .get(`client/deal/${this.$route.params.uuid}/topic`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {

                        this.topicItems = res.body.data

                    })
                    .catch(err => {
                        this.topicItems = []
                        this.$toastr.error(this.$t('failed_to_get_list_topics'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            show_info(type) {
                this.tab_type = type;
                if (type === 'tracking') {
                    this.getTrackingDeal()
                }
                else if (type === 'event') {
                    this.getTrackingLog()
                }

            },
            async getTrackingDeal() {
                var _this = this;
                this.loading = true;

                let params = {};


                await this.$http
                    .get("client/tracking/deal/" + this.detail_tracking.deal_uuid, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.referencePoints = []
                        this.tracking_geo = res.body.data.tracking[0];
                        this.coordinates = this.tracking_geo.from.point

                        this.referencePoints.push(this.tracking_geo.from.point);
                        if (this.tracking_geo && this.tracking_geo.tracking) {
                            for (var i = 0; i < this.tracking_geo.tracking.length; i++) {
                                this.referencePoints.push(this.tracking_geo.tracking[i].point)
                            }
                        }


                        this.ymapInit()
                    })
                    .catch(err => {
                        this.tracking_geo = []
                        this.$toastr.error(this.$t('failed_to_get_list_tracking'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async getTrackingLog() {
                var _this = this;
                this.loading = true;

                let params = {};

                await this.$http
                    .get("client/tracking_log/" + this.detail_tracking.uuid, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.trackingLogItems = res.body.data;
                    })
                    .catch(err => {
                        this.trackingLogItems = []
                        this.$toastr.error(this.$t('failed_to_get_list_tracking_logs'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },

            close_info() {
                this.detail_tracking = {};
                this.dialogDetailTracking = false;
                this.tab_type = 'data';
            },
            recalculate(){
                let total_umount = 0;
                let total_umount_nds = 0;
                this.dealOrderItems.forEach(function callback(item2) {
                    if (typeof item2.total_usd === 'string' || item2.total_usd instanceof String){
                        item2.total_usd = item2.total_usd.replace(/\s/g,'')
                        item2.total_usd = (item2.total_usd !== "") ? item2.total_usd : 0;
                    }
                    total_umount = parseFloat(total_umount) + parseFloat(item2.total_usd);
                    if(item2.nds > 0){
                        total_umount_nds = parseFloat(total_umount_nds) + (parseFloat(item2.total_usd)  * (parseFloat(item2.nds) / 100));
                    }

                    if (typeof item2.total_usd === 'string' || item2.total_usd instanceof String){
                        item2.total_usd = item2.total_usd.replace(/\s/g,'')
                    }
                    item2.total_usd = new Intl.NumberFormat('ru-RU').format(parseFloat(item2.total_usd))

                    if (typeof item2.total === 'string' || item2.total instanceof String){
                        item2.total = item2.total.replace(/\s/g,'')
                    }
                    item2.total = new Intl.NumberFormat('ru-RU').format(parseFloat(item2.total))

                    if (typeof item2.price === 'string' || item2.price instanceof String){
                        item2.price = item2.price.replace(/\s/g,'')
                    }
                    item2.price = new Intl.NumberFormat('ru-RU').format(parseFloat(item2.price))

                    if (typeof item2.discount === 'string' || item2.discount instanceof String){
                        item2.discount = item2.discount.replace(/\s/g,'')
                    }
                    item2.discount = new Intl.NumberFormat('ru-RU').format(parseFloat(item2.discount))

                    if (typeof item2.extra_charge === 'string' || item2.extra_charge instanceof String){
                        item2.extra_charge = item2.extra_charge.replace(/\s/g,'')
                    }
                    item2.extra_charge = new Intl.NumberFormat('ru-RU').format(parseFloat(item2.extra_charge))


                });

                //this.deal_order_nds_total = total_umount * (12 / 100);
                this.deal_order_nds_total = total_umount_nds;
                this.deal_order_no_nds_total = total_umount - this.deal_order_nds_total;

                this.deal_order_total = new Intl.NumberFormat('ru-RU').format(total_umount)
                this.deal_order_no_nds_total = new Intl.NumberFormat('ru-RU').format(parseFloat(this.deal_order_no_nds_total))
                this.deal_order_nds_total = new Intl.NumberFormat('ru-RU').format(parseFloat(this.deal_order_nds_total))

            },
            showDetailsApplication3(item){
                if(item.show === true){
                    return;
                }
                if(this.click_action===true){
                    this.click_action = false;
                    return;
                }
                this.deal_uuid = item.uuid
                this.show_info('data')
                this.clickMenuIcon = 1;
                this.trackings.map(detail => {
                        if(detail.id !== item.id){
                            detail.show = false
                        }

                    }
                )
                item.show = !item.show;

            },
            showDetailsApplication2(item){
                this.clickMenuIcon = 1;
                if(item.show === true){
                    return;
                }
                if(this.click_action===true){
                    this.click_action = false;
                    return;
                }
                this.trackings.map(detail => {
                        if(detail.id !== item.id){
                            detail.show = false
                        }

                    }
                )
                item.show = !item.show;
            },

            showDetailsApplication(item){
                this.detail_tracking = {};
                this.detail_tracking = item;
                this.dialogDetailTracking = true;
            },
            downloadFile(data) {
                fetch(data.url)
                    .then((resp) => resp.blob())
                    .then((blob) => {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.style.display = "none";
                        a.href = url;
                        a.download = data.file_name;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        this.$toastr.success(this.$t('file_download_started'))
                    })
                    .catch(() =>
                        this.$toastr.error(this.$t('file_download_error'))

                    );
            },
            openChat(item){
                this.$router.push({
                    name: 'topic.messages',
                    params: {
                        uuid: item.uuid
                    }
                })
            }





        }
    }
</script>

<style>
.rowDealForm > td >  div, .rowDealForm > td, .rowMobiClass > td >  div, .rowMobiClass > td{
    font-size: inherit !important;
}
.rowMobiClass{
    padding: 20px 0 0 0 ;
}
.rowMobiClass > td:last-child {
  //border: solid 1px red !important;
}
.rowDealForm td:nth-child(1){
    width: 58% !important;
}
.rowDealForm td:nth-child(2){
    width: 8% !important;
}
.rowDealForm td:nth-child(3){
    width: 12% !important;
}
.rowDealForm td:nth-child(4){
    width: 10% !important;
}
.rowDealForm td:nth-child(5){
    width: 12% !important;
}
</style>

<style scoped>

.wrapper-progressBar {
    width: 100%
}




.ymap-container {
    height: 300px;
}





</style>


<style scoped>
.v-card__text{
    font-size: inherit;
}
.deal_col_50{
    width: 50% !important;
    max-width: 50% !important;
}
.deal_bold{
    font-weight: bold;
}
.color_grey{
    color: rgba(64, 64, 64, 0.6) !important
}
.color_grey_header{
    color: rgba(77, 77, 77, 0.38) !important
}
.tracking_link{
    font-weight: bold;
}




.roadmap_wrapper {
    overflow-x: auto;
    white-space: nowrap;
}


.roadmap {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 60px 0 0 0;
    height: 50px;

}
.li1_2 {
    text-align: center;
    position: relative;

}
.li1_2 p{
    position: absolute;
    top: -60px;
    min-width: 200px;
    left: 0;
    text-align: left;
}
.node_2 {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin: 0 5px;
}
.blue_point_2 {
    background-color: #3498db;
}
.grey_point_2 {
    background-color: #bdc3c7;
}
.my_divider_2 {
    height: 1px;
    position: relative;
    top: 0;
    min-width: 287px !important;
}
.my_divider_3 {
    height: 1px;
    position: relative;
    top: 0;
    min-width: 590px !important;
}
.blue_line_2 {
    background-color: #3498db;
}
.grey_line_2 {
    background-color: #bdc3c7;
}
.li_last_bold {
    font-weight: bold;
}
.li_last_finish_2 {
    left: -190px !important;
    text-align: right !important;
}
.blue_color_2 {
    color: #3498db;
}
.grey_color_2 {
    color: #bdc3c7;
}

.grey_date_2{
    color: #B3B3B3 !important;
}

.li_last_center_2{
    left: -98px !important;
    text-align: center !important;
}

.li_last{
    left: -95px !important;
}

li.my_divider{
    margin-top: 3px !important;
    height: 1px !important;
}
.node{
    height: 8px !important;
    width: 8px !important;
}
.border_card{
    border: solid 1px #007EC9 !important;
}


@media (max-width: 960px) {
    .v-toolbar{
        flex: none;
    }
    .tabs-nav{
        margin-bottom: 8px;
    }

    .my_divider_3{
        min-width: 93% !important
    }

    .tabs-nav__item{
        margin-right: 0;
    }
    .distance_0{
        padding: 10px 0 20px 0 !important;
    }


}
</style>
